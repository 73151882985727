import Image from "next/image"
import React from "react"

import { Links } from "config/links"

import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"

import { Aim } from "../../../shared/aim"
import { Box } from "../../../shared/box"
import { Button } from "../../../shared/landing/button"

export function WelcomeSection() {
  return (
    <Section className="mt-40">
      <section>
        <div className="flex flex-col w-full max-w-[1200px] text-white justify-between items-stretch md:flex-row">
          <Box className="flex-1 shrink-0 md:border-l-2 md:border-primary">
            <div className="my-auto md:max-w-[470px]">
              <div className="flex flex-col md:ml-[52px]">
                <h1 className="text-h2 font-bold text-white text-center md:text-left">
                  Welcome to <span className="text-primary">Cryptobots spaceship</span>
                </h1>
                <p className="mt-4 text-white text-table font-medium text-center md:text-left">
                  A historic 2017 NFT collection with renounced contract ownership, long history, and a roadmap!
                </p>
                <div className="mt-10">
                  <Link href={Links.OPENSEA_CLASSIC} target="_blank">
                    <Button className="uppercase w-full md:w-auto" size="lg">
                      Buy Now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Box>
          <Aim className="flex-1 mt-10 md:max-w-[568px] md:my-auto md:ml-5">
            <Image
              src="/images/pages/classic/welcome-section/bot.png"
              alt="computer image"
              width={1200}
              height={1200}
              layout={"responsive"}
            />
          </Aim>
        </div>
      </section>
    </Section>
  )
}
