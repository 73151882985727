import React from "react"

import { Links } from "config/links"

import { Box } from "components/shared/box"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"

import type { BlockProps } from "../../../shared/landing/block"
import { Block } from "../../../shared/landing/block"
import { Button } from "../../../shared/landing/button"
import { Text } from "../../../shared/text"

export function UniqueSection() {
  const blocks: Array<BlockProps> = [
    {
      title: "One of the First",
      text: "Classic Cryptobots were launched in 2017 and featured in one of the very first NFT.",
      iconPath: "/images/pages/classic/unique-section/trophy.svg",
    },
    {
      title: "Only 10 000 NFT’s",
      text: "The collection is limited with no possibilities of new minting. Contract ownership and management roles were assigned to the address of Ethereum founder Vitalik Buterin.",
      iconPath: "/images/pages/classic/unique-section/photos.svg",
    },
    {
      title: "Commercials rights",
      text: "Classic Collection holders have ownership and commercial usage rights over their NFTs.",
      iconPath: "/images/pages/classic/unique-section/clipboard.svg",
    },
  ]

  return (
    <Section className="mt-10 md:mt-36">
      <section className="flex flex-col items-center justify-center md:mt-10 text-white">
        <Text as="h2" className="text-center">
          Unique <span className="text-primary">NFT Collection</span>
        </Text>

        <div className="mt-8 w-full flex items-center justify-center gap-6 flex-wrap md:items-stretch md:gap-9 md:flex-row">
          {blocks.map((block, index) => {
            return <Block className="w-full md:w-[calc(100%/3-24px)] justify-start" key={index} {...block} />
          })}
        </div>

        <Box center row className="w-full mt-10">
          <Link href={Links.OPENSEA_CLASSIC} target="_blank">
            <Button className="w-full md:w-auto" size="lg">
              Cryptobots OpenSea
            </Button>
          </Link>
        </Box>
      </section>
    </Section>
  )
}
