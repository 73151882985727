// import { maxWidth } from "./layout"
import { useRouter } from "next/router"

import { Box } from "../box"
import { ArrowBack } from "../icons/arrow-back"
import { Text } from "../text"
import { Button } from "./button"

type SectionProps = {
  showBackButton?: boolean
  children?: React.ReactNode
  header?: React.ReactNode | string
  className?: string
  higherBottomMargin?: boolean
}

export function Section({
  children,
  showBackButton,
  header,
  className = "",
  higherBottomMargin = false,
}: SectionProps) {
  const router = useRouter()

  return (
    <Box
      row
      full
      // TODO REMOVE MARGIN
      className={`relative justify-center overflow-hidden mb-10 ${
        higherBottomMargin ? "md:mb-[120px]" : "md:mb-15"
      } px-4 md:px-12 lg:px-4 ${className}`}
    >
      <Box full className={`max-w-[1200px] text-white relative`}>
        {showBackButton && (
          <div className="absolute top-0 left-0">
            <Button type="transparent" className="self-start" noPadding noVolume onClick={() => router.back()}>
              <ArrowBack />
              <Text color="primary" className="ml-2">
                Back
              </Text>
            </Button>
          </div>
        )}
        {header && (
          <Text as="h2" className="text-center mb-8">
            {header}
          </Text>
        )}
        {children}
      </Box>
    </Box>
  )
}
