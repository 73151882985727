import { BotsSection } from "../../sections/bots-section"
import { Box } from "components/shared/box"
import { Button } from "components/shared/landing/button"
import { Text } from "components/shared/text"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"
import { Links } from "config/links"
import { Carousel } from "components/shared/landing/carousel"

export default function BotsClassicSection() {
  const bots = [
    {
      botImg: "/images/pages/classic/bots-classic-section/3021.svg",
      game: "Cryptobots Classic",
      collection: "Cryptobot Classic",
      botId: "3021",
      price: "0.28",
      subtitle: "Runs fast and destroys",
    },
    {
      botImg: "/images/pages/classic/bots-classic-section/5540.svg",
      game: "Cryptobots Classic",
      collection: "Cryptobot Classic",
      botId: "5540",
      price: "0.32",
      subtitle: "Ready to Win",
    },
    {
      botImg: "/images/pages/classic/bots-classic-section/5631.svg",
      game: "Cryptobots Classic",
      collection: "Cryptobot Classic",
      botId: "5631",
      price: "0.46",
      subtitle: "Strong intelligence",
    },
    {
      botImg: "/images/pages/classic/bots-classic-section/13041.svg",
      game: "Cryptobots Classic",
      collection: "Cryptobot Classic",
      botId: "13041",
      price: "0.4",
      subtitle: "Cute and strong",
    },
  ]

  return (
    <Section className="md:mt-36">
      <Box full center className="relative">
        <Text as="h2" color="white" className="text-center md:text-left">
          Cryptobots Classic 2017 <span className="text-primary">Collection</span>
        </Text>
        <Carousel>
          <BotsSection bots={bots} theme="classic" />
        </Carousel>
        <Link href={Links.OPENSEA_CLASSIC} target="_blank">
          <Button className="uppercase mt-10 my-10 w-[220px]">See All</Button>
        </Link>
      </Box>
    </Section>
  )
}
