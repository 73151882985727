import React, { useEffect, useState } from "react"
import { useTailwindBreakpoints } from "../hooks/use-tailwind-breakpoints"

type BackgroundImageProps = {
  children: React.ReactNode
  imageSrc?: string
  hideMobile?: boolean
  className?: string
  contain?: boolean
}

export function BackgroundImage({
  children,
  imageSrc,
  hideMobile = false,
  className = "",
  contain,
}: BackgroundImageProps) {
  const { xs, sm } = useTailwindBreakpoints()
  const [bgImageStyle, setBgImageStyle] = useState<{ backgroundImage?: string }>({
    backgroundImage: `url(${imageSrc})`,
  })

  useEffect(() => {
    hideMobile && setBgImageStyle(!(xs || sm) ? { backgroundImage: `url(${imageSrc})` } : {})
  }, [imageSrc, hideMobile, xs, sm])

  return (
    <div style={bgImageStyle} className={`${contain ? "bg-contain" : "bg-cover"} ${className}`}>
      {children}
    </div>
  )
}
