import React from "react"
import { getIconSize } from "../../../utils/get-icon-size"

export function ArrowBack({
  fill = "#F8A2F1",
  h = 40,
  w = 40,
  className,
}: {
  fill?: string
  h?: string | number
  w?: string | number
  className?: string
}) {
  return (
    <svg
      className={`w-[${getIconSize(w)}] h-[${getIconSize(h)}] ${className}`}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5116_2224)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9092 32H19.9955C19.4647 32 18.9556 31.7889 18.5804 31.4133L8.58496 21.4053C7.80822 20.6276 7.80465 19.3688 8.57698 18.5867L18.4441 8.59471C18.8199 8.21418 19.3324 8 19.8672 8H24.1072C25.8884 8 26.781 10.153 25.5223 11.4133L18.4226 18.5219C17.6179 19.3276 17.6474 20.6416 18.4874 21.4105L26.2596 28.5247C27.6056 29.7567 26.734 32 24.9092 32ZM29.6111 25.472L25.4945 21.4167C24.7024 20.6364 24.6988 19.3599 25.4865 18.5751L29.5624 14.5142C29.8929 14.1849 30.3405 14 30.807 14C32.3843 14 33.1682 15.9126 32.0445 17.0195L30.5315 18.5099C29.7112 19.3181 29.741 20.6503 30.5967 21.4209L32.128 22.8C33.3781 23.9257 32.5817 26 30.8995 26C30.4174 26 29.9546 25.8103 29.6111 25.472Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5116_2224"
          x="6.10352e-05"
          y="0"
          width="40.739"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.749333 0 0 0 0 0.216667 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5116_2224" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5116_2224" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
