import React from "react"
import Image from "next/image"
import { GradientBox } from "../gradient-box"

export type BlockProps = {
  className?: string
  iconPath?: string
  title?: string
  text?: string
}

export function Block({ iconPath, title, text, className }: BlockProps) {
  return (
    <GradientBox className={`flex px-3 py-8 flex-col items-center ${className}`}>
      {iconPath && (
        <div className="relative m-1 w-[50px] h-[50px]">
          <Image src={iconPath} alt="icon" layout={"fill"} />
        </div>
      )}
      {title && <div className="mt-4 text-h3-mobile text-center">{title}</div>}
      {text && <p className="mt-4 text-table text-center opacity-80 whitespace-pre-wrap">{text}</p>}
    </GradientBox>
  )
}
