import React from "react"

export function ArrowRight({ fill = "white", h = 20, w = 20 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Outline/arrow-right">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16634 10.8332H14.0538L11.0263 14.4665C10.7313 14.8198 10.7797 15.3457 11.133 15.6398C11.4872 15.9348 12.0122 15.8865 12.3072 15.5332L16.4738 10.5332C16.5063 10.494 16.5222 10.4482 16.5463 10.4048C16.5663 10.3698 16.5905 10.3398 16.6055 10.3015C16.643 10.2057 16.6655 10.1048 16.6655 10.0032C16.6655 10.0023 16.6663 10.0007 16.6663 9.99984C16.6663 9.999 16.6655 9.99734 16.6655 9.9965C16.6655 9.89484 16.643 9.794 16.6055 9.69817C16.5905 9.65984 16.5663 9.62984 16.5463 9.59484C16.5222 9.55151 16.5063 9.50567 16.4738 9.46651L12.3072 4.4665C12.1413 4.269 11.9047 4.1665 11.6663 4.1665C11.478 4.1665 11.2888 4.22984 11.133 4.35984C10.7797 4.654 10.7313 5.17984 11.0263 5.53317L14.0538 9.1665H4.16634C3.70634 9.1665 3.33301 9.53984 3.33301 9.99984C3.33301 10.4598 3.70634 10.8332 4.16634 10.8332Z"
          fill={fill}
        />
        <mask id="mask0_285_11356" maskUnits="userSpaceOnUse" x="3" y="4" width="14" height="12">
          <path
            id="Mask_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.16634 10.8332H14.0538L11.0263 14.4665C10.7313 14.8198 10.7797 15.3457 11.133 15.6398C11.4872 15.9348 12.0122 15.8865 12.3072 15.5332L16.4738 10.5332C16.5063 10.494 16.5222 10.4482 16.5463 10.4048C16.5663 10.3698 16.5905 10.3398 16.6055 10.3015C16.643 10.2057 16.6655 10.1048 16.6655 10.0032C16.6655 10.0023 16.6663 10.0007 16.6663 9.99984C16.6663 9.999 16.6655 9.99734 16.6655 9.9965C16.6655 9.89484 16.643 9.794 16.6055 9.69817C16.5905 9.65984 16.5663 9.62984 16.5463 9.59484C16.5222 9.55151 16.5063 9.50567 16.4738 9.46651L12.3072 4.4665C12.1413 4.269 11.9047 4.1665 11.6663 4.1665C11.478 4.1665 11.2888 4.22984 11.133 4.35984C10.7797 4.654 10.7313 5.17984 11.0263 5.53317L14.0538 9.1665H4.16634C3.70634 9.1665 3.33301 9.53984 3.33301 9.99984C3.33301 10.4598 3.70634 10.8332 4.16634 10.8332Z"
            fill={fill}
          />
        </mask>
        <g mask="url(#mask0_285_11356)"></g>
      </g>
    </svg>
  )
}
