import React from "react"

import BotsClassicSection from "components/pages/classic/bots-classic-section"
import { Box } from "components/shared/box"

import { QuestionSection } from "../components/pages/classic/question-section"
import { UniqueSection } from "../components/pages/classic/unique-section"
import { WelcomeSection } from "../components/pages/classic/welcome-section"
import { BackgroundImage } from "../components/shared/background-img"

export default function Index() {
  return (
    <Box className="flex-1">
      <BackgroundImage
        imageSrc="/images/pages/classic/bg-telescope.jpg"
        className="bg-auto md:bg-no-repeat md:bg-contain 2xl:bg-cover"
      >
        <WelcomeSection />
        <BackgroundImage
          imageSrc="/images/pages/classic/stars-background.png"
          className="bg-auto md:bg-repeat-x md:bg-contain"
        >
          <UniqueSection />
          <BotsClassicSection />
          <QuestionSection />
        </BackgroundImage>
      </BackgroundImage>
    </Box>
  )
}
