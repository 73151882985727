import { useMemo } from "react"

import { useWindowSize } from "./use-window-size"

export enum MinWidth {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  // can't start with number, so XL2 instead of XL2
  XL2 = 1536,
}

export function useTailwindBreakpoints() {
  const { width } = useWindowSize()
  const xs = width < MinWidth.SM
  const sm = width >= MinWidth.SM && width < MinWidth.MD
  const md = width >= MinWidth.MD && width < MinWidth.LG
  const lg = width >= MinWidth.LG && width < MinWidth.XL
  const xl = width >= MinWidth.XL && width < MinWidth.XL2
  const xl2 = width >= MinWidth.XL

  const isMobile = xs || sm || md

  return useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      xl2,
      isMobile,
      width,
    }),
    [isMobile, lg, md, sm, width, xl, xl2, xs],
  )
}
