import React from "react"
import { getIconSize } from "utils/get-icon-size"

export function RightArrow({
  fill = "white",
  h = 16,
  w = 10,
  className = "",
  pale,
}: {
  fill?: string
  h?: string | number
  w?: string | number
  className?: string
  pale?: boolean
}) {
  return (
    <svg
      width={w}
      height={h}
      className={`w-[${getIconSize(w)}] h-[${getIconSize(h)}] ${pale ? "opacity-40" : ""} ${className}`}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.56039 8.00004C9.56039 8.2868 9.4509 8.57351 9.23239 8.79214L2.35268 15.6718C1.91504 16.1094 1.20549 16.1094 0.768035 15.6718C0.330577 15.2343 0.330577 14.5249 0.768035 14.0872L6.85558 8.00004L0.768247 1.91282C0.33079 1.47518 0.33079 0.765845 0.768247 0.328422C1.20571 -0.109425 1.91525 -0.109425 2.35289 0.328422L9.2326 7.20795C9.45115 7.42668 9.56039 7.7134 9.56039 8.00004Z"
        fill={fill}
      />
    </svg>
  )
}
