import Image from "next/image"
import React from "react"
import { trackBuyNowBot } from "services/analytics"

import { Links } from "config/links"

import { ArrowRight } from "components/shared/icons/arrow-right"
import { EthereumStill } from "components/shared/icons/ethereum-still"
import { Button } from "components/shared/landing/button"
import { GradientBox } from "components/shared/landing/gradient-box"
import { Link } from "components/shared/link"

import { Box } from "../../shared/box"
import { Text } from "../../shared/text"
import styles from "./bots-section.module.scss"

/* eslint-disable @next/next/no-img-element */
type BotsSectionProps = {
  botImg: string
  game: string
  collection: string
  botId: string
  price: string
  subtitle: string
  url?: string
}

export function BotsSection({ bots, theme = "default" }: { bots: BotsSectionProps[]; theme?: "classic" | "default" }) {
  const isClassic = theme === "classic"

  return (
    <div className="mt-8 w-auto flex flex-row gap-3 md:gap-6 h-[370px] md:h-[340px]">
      {bots.map((bot, index) => (
        <Card key={bot.botId} {...bot} isClassic={isClassic} index={index} />
      ))}
    </div>
  )
}

type CardProps = BotsSectionProps & {
  isClassic: boolean
  index: number
}
const Card = ({ botImg, game, collection, botId, price, subtitle, isClassic, index, url }: CardProps) => {
  const link =
    url ||
    (isClassic
      ? Links.OPENSEA_CLASSIC
      : `https://opensea.io/assets/0x4df461f8c22c0bfb40a51560597d5b946d869d5c/${botId}`)

  return (
    <GradientBox
      center
      className={`w-[228px] h-[344px] md:w-[282px] md:h-[340px] shadow-md rounded-box relative ${styles.bots__card}`}
    >
      <Image src={botImg} alt="Cryptobot" height={284} width={284} />
      <Box
        center
        className={`absolute w-full bottom-0 bg-gradient-to-b from-transparent to-[rgba(79, 74, 204, 0)] backdrop-blur-md px-4 pb-4 pt-2 rounded-box ${styles.bots__card_params}`}
      >
        <Text as="p" size="tiny" className="opacity-60 mb-1/2">
          {game}
        </Text>
        <Text as="p" size="sm" className="mb-1/2">
          {collection} #{botId}
        </Text>
        <Box row center>
          <span className="mr-1">
            <EthereumStill fill="white" h={24} w={16} />
          </span>
          <Text as="p" size="sm" className="justify-center items-center">
            {price}
          </Text>
        </Box>
        <div className="my-2 w-[12px] h-[1px] bg-primary"></div>
        <Text as="p" size="sm" weight="regular" className="text-center">
          {subtitle}
        </Text>
      </Box>
      <Box center className={`absolute w-full bottom-0 px-4 pb-4 pt-2 rounded-box ${styles.bots__card_buy}`}>
        <Link
          href={link}
          target="_blank"
          withRedirect
          onClick={() => trackBuyNowBot(`0${index + 1}` as "01" | "02" | "03" | "04" | "05")}
        >
          <Button
            noOverlay
            noVolume
            className="w-full bg-transparent border border-primary hover:bg-transparent text-white rounded !bg-none !font-bold text-sm !text-shadow-none !normal-case"
          >
            <span className="mr-2">Buy now</span> <ArrowRight />
          </Button>
        </Link>
      </Box>
    </GradientBox>
  )
}
