import React from "react"
import Image from "next/image"
import { Section } from "components/shared/landing/section"
import { Aim } from "../../../shared/aim"
import { Button } from "../../../shared/landing/button"
import { Text } from "../../../shared/text"
import { Link } from "components/shared/link"
import { Links } from "config/links"

export function QuestionSection() {
  return (
    <Section className="mt-0">
      <div className="flex mt-20 pb-20 w-full flex-col max-w-[1200px] text-white justify-center items-stretch md:mt-36 md:items-center md:flex-row">
        <div className="flex flex-1 shrink-0">
          <div className="flex flex-col my-auto md:border-l-2 md:border-primary md:min-h-[320px]">
            <section className="flex flex-col my-auto md:ml-[52px]">
              <Text as="h2" className="text-center md:text-left">
                Got questions?
              </Text>
              <Text as="p" className="mt-4 text-table font-medium text-center md:text-left">
                You could ask Cryptobots team or Cryptobots holders in our Discord community
              </Text>
              <div className="mt-4 md:mt-10">
                <Link href={Links.DISCORD} target="_blank">
                  <Button className="w-full md:w-auto" size="lg">
                    Join our discord
                  </Button>
                </Link>
              </div>
            </section>
          </div>
        </div>
        <Aim className="flex-1 mt-10 md:max-w-[520px] md:my-auto md:ml-5">
          <div className="rounded-2xl overflow-hidden">
            <Image
              src="/images/pages/classic/question-section/bot.png"
              alt="among us image"
              width={520}
              height={484}
              layout={"responsive"}
            />
          </div>
        </Aim>
      </div>
    </Section>
  )
}
