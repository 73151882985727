import React, { useCallback, useEffect, useRef, useState } from "react"
import { LeftArrow } from "components/shared/icons/left-arrow"
import { RightArrow } from "components/shared/icons/right-arrow"
import { Box } from "../../box"
import { Button } from "../button"

import styles from "./index.module.scss"
import { useWindowSize } from "components/hooks/use-window-size"

const SCROLL_STEP_SIZE = 400

type CarouselProps = {
  children: React.ReactNode | string | null
  className?: string
}

export function Carousel({ children, className }: CarouselProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  const size = useWindowSize()
  const [enableButtons, setEnableButtons] = useState({ left: false, right: false })
  const showButtons = enableButtons.left || enableButtons.right

  const updateButtonsState = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current

      const enableLeftButton = scrollLeft > 1
      const enableRightButton = scrollLeft < scrollWidth - clientWidth - 1

      if (enableLeftButton !== enableButtons.left || enableRightButton !== enableButtons.right) {
        setEnableButtons({ left: enableLeftButton, right: enableRightButton })
      }
    }
  }, [enableButtons.left, enableButtons.right])

  const scrollBy = (value: number) => {
    if (containerRef.current) {
      const { scrollLeft } = containerRef.current as HTMLElement

      containerRef.current.scrollTo({
        left: scrollLeft + value,
        behavior: "smooth",
      })
    }
  }

  const scrollToRight = useCallback(() => {
    scrollBy(SCROLL_STEP_SIZE)
  }, [])

  const scrollToLeft = useCallback(() => {
    scrollBy(-SCROLL_STEP_SIZE)
  }, [])

  useEffect(() => {
    updateButtonsState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size])

  return (
    <Box className="relative w-full">
      <Box className={`overflow-hidden w-full`}>
        {showButtons && (
          <>
            <Box className="absolute left-[-10px] top-[45%] z-10">
              <Button
                onClick={scrollToLeft}
                type="primary"
                disabled={!enableButtons.left}
                noPadding
                noVolume
                noOverlay
                className="p-4"
              >
                <LeftArrow />
              </Button>
            </Box>
            <Box className="absolute right-[-10px] top-[45%] z-10">
              <Button
                onClick={scrollToRight}
                type="primary"
                disabled={!enableButtons.right}
                noPadding
                noVolume
                noOverlay
                className="p-4"
              >
                <RightArrow />
              </Button>
            </Box>
          </>
        )}

        <div
          className={`${className} overflow-x-auto flex flex-row flex-shrink-0 ${styles["carousel-scroll-container"]}`}
          ref={containerRef}
          onScroll={updateButtonsState}
        >
          {children}
        </div>
      </Box>
    </Box>
  )
}
